import React, { useEffect, useState } from "react";
import logo from "./logo.png";
import "./FinalPage.css";
import navbutton from "./navbutton.png";
import cross from "./cross.png";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/signup.services";
import SuggestPassword from "../../services/GenerateKey";
import Swal from "sweetalert2";
const dsteem = require("dsteem");

const openSideNav = () => {
    document.querySelector(".sidenav").style.display = "flex";
};
const closeSideNav = () => {
    document.querySelector(".sidenav").style.display = "none";
};

const FinalPage = (props) => {
    let opts = {};
    //connect to production server
    opts.addressPrefix = "WTH";
    opts.chainId =
        "d909c4dfab0369c4ae4f4acaf2229cc1e49b3bba0dffb36a37b6174a6f391e2e";
    const client = new dsteem.Client("https://bapi.wortheumwallet.com");
    const steemClient = new dsteem.Client("https://bapi.wortheumwallet.com");

    const history = useHistory();
    const [user, setUser] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [referalUser, setreferalUser] = useState();
    // const [privatekey, setprivatekey] = useState("");
    const [tx, setTx] = useState(false);
    const [privateKeys, setprivateKeys] = useState("");
    const [sp, setsp] = useState(process.env.REACT_APP_SP);
    // const [copied, setcopied] = useState(false);
    const [accountSuccess, setaccountSuccess] = useState(false);

    useEffect(() => {
        let token = localStorage.getItem("auth-token-wortheum");
        if (!token) {
            history.push("/register");
        }
        AuthService.getUserDetails(token).then(
            (response) => {
                console.log(response.data);
                if (response.data.user.status !== "Active") {
                    history.push("/");
                    setTx(false);
                } else {
//                    localStorage.removeItem("auth-token-wortheum");
                    setUser(response.data.user);
                    setUsername(response.data.user.username);
                    setPassword(response.data.user.masterPassword);
                    setreferalUser(response.data.user.referralUser);
                    setTx(true);
                }
            },
            (error) => {
                // console.log(error);
                history.push("/register");
                setTx(false);
            }
        );
    }, []);

    useEffect(() => {
        document.body.style.zoom = "100%";
    }, []);
    useEffect(() => {
        //create with Wortheum function
        const submitTx = async (username, password) => {
            const ownerKey = dsteem.PrivateKey.fromLogin(
                username,
                password,
                "owner"
            );
            const activeKey = dsteem.PrivateKey.fromLogin(
                username,
                password,
                "active"
            );
            const postingKey = dsteem.PrivateKey.fromLogin(
                username,
                password,
                "posting"
            );
            const memoKey = dsteem.PrivateKey.fromLogin(
                username,
                password,
                "memo"
            ).createPublic(opts.addressPrefix);
            const ownerAuth = {
                weight_threshold: 1,
                account_auths: [],
                key_auths: [[ownerKey.createPublic(opts.addressPrefix), 1]],
            };
            const activeAuth = {
                weight_threshold: 1,
                account_auths: [],
                key_auths: [[activeKey.createPublic(opts.addressPrefix), 1]],
            };
            const postingAuth = {
                weight_threshold: 1,
                account_auths: [],
                key_auths: [[postingKey.createPublic(opts.addressPrefix), 1]],
            };
            const privateKey = dsteem.PrivateKey.fromString(
                process.env.REACT_APP_WOBDACTIVE_KEY
            );
            const op = [
                "account_create",
                {
                    fee: "1.000 WORTH",
                    creator: process.env.REACT_APP_WORTHONBOARD,
                    new_account_name: username,
                    owner: ownerAuth,
                    active: activeAuth,
                    posting: postingAuth,
                    memo_key: memoKey,
                    json_metadata: "",
                },
            ];
            await client.broadcast.sendOperations([op], privateKey).then(
                function (result) {
                    let res = `Included in block: ${result.block_num}`;
                    Swal.fire("Good job!", res, "success");
                    localStorage.removeItem("auth-token-wortheum");
                    setaccountSuccess(true);
                },
                function (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error,
                    });
                    setaccountSuccess(true);
                    console.error(error);
                }
            );
        };

        const sendDelegation = async () => {
            const lib = dsteem;
            const client = steemClient;
            const ops = [];
            if (sp > 0) {
                // Converting SP to VESTS
                const delegation = lib
                    .getVestingSharePrice(
                        await client.database.getDynamicGlobalProperties()
                    )
                    .convert({ amount: sp, symbol: "WORTH" });

                const delegate_op = [
                    "delegate_vesting_shares",
                    {
                        delegatee: username,
                        delegator: process.env.REACT_APP_USER_CREATOR,
                        vesting_shares: delegation,
                    },
                ];

                ops.push(delegate_op);
                console.log("delegation", delegation);
            }
            if (process.env.REACT_APP_ACTIVE_KEY) {
                client.broadcast
                    .sendOperations(
                        ops,
                        lib.PrivateKey.from(process.env.REACT_APP_ACTIVE_KEY)
                    )
                    .then((r) => {
                        console.log(r);
                        Swal.fire(
                            "Congrats!",
                            "Your bonus delegation coin has been transferred to your account",
                            "success"
                        );
                    })
                    .catch((e) => {
                        console.log(e);
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: e.message,
                        });
                    });
            }
        };

        if (tx) {
            submitTx(username, password);
        }
        if (setaccountSuccess) {
            sendDelegation();
        }
    }, [tx]);

    useEffect(() => {
        const sendWorthpower = async () => {
            const lib = dsteem;
            const client = steemClient;
            const ops = [];
            const powerup_op = [
                "transfer_to_vesting",
                {
                    from: process.env.REACT_APP_USER_CREATOR,
                    to: referalUser,
                    amount: process.env.REACT_APP_DELEGATION,
                },
            ];

            ops.push(powerup_op);
            console.log("refferal", referalUser);
            if (process.env.REACT_APP_ACTIVE_KEY) {
                client.broadcast
                    .sendOperations(
                        ops,
                        lib.PrivateKey.from(process.env.REACT_APP_ACTIVE_KEY)
                    )
                    .then((r) => {
                        console.log(r);
                        Swal.fire(
                            "Congrats!",
                            "1 Wortheum power has been transferred to your refferal account",
                            "success"
                        );
                    })
                    .catch((e) => {
                        console.log(e);
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: e.message,
                        });
                    });
            }
        };
        if (referalUser && accountSuccess) {
            sendWorthpower();
        }
    }, [accountSuccess]);

    // Generates Aall Private Keys from username and password
    useEffect(() => {
        const getPrivateKeys = () => {
            let roles = ["owner", "active", "posting", "memo"];
            const privKeys = {};
            roles.forEach((role) => {
                privKeys[role] = dsteem.PrivateKey.fromLogin(
                    username,
                    password,
                    role
                ).toString();
                privKeys[`${role}Pubkey`] = dsteem.PrivateKey.from(
                    privKeys[role]
                )
                    .createPublic()
                    .toString();
            });
            setprivateKeys(privKeys);
            return privKeys;
        };
        getPrivateKeys();
    }, []);
    
    return (
        <div id="page4" className="App">
            <div className="nv">
                <div className="lgo navlink">
                    <img src={logo} alt="logo" />
                </div>
                <div className="links">
                    <span className="navlink"><a href="https://wortheum.news" target="_blank" rel="noopener noreferrer">Wortheum News</a></span>
                    <span className="navlink"><a href="https://worthshots.com" target="_blank" rel="noopener noreferrer">Worthshots</a></span>
                    <span className="navlink"><a href="https://worth.tube" target="_blank" rel="noopener noreferrer">Worthtube</a></span>
                    <span className="navlink"><a href="https://wortheumwallet.com" target="_blank" rel="noopener noreferrer">Wallet</a></span>
                    <span className="navlink"><a href="https://ads.wortheum.news" target="_blank" rel="noopener noreferrer">Ad Network</a></span>
                    <img
                        src={navbutton}
                        className="nvbtn"
                        alt="nav button"
                        onClick={openSideNav}
                    />
                </div>
            </div>

            <div className="sidenav">
                <img
                    src={cross}
                    className="cross navlink"
                    alt="cross"
                    onClick={closeSideNav}
                />
                    <span className="navlink"><a href="https://wortheum.news" target="_blank" rel="noopener noreferrer">Wortheum News</a></span>
                    <span className="navlink"><a href="https://worthshots.com" target="_blank" rel="noopener noreferrer">Worthshots</a></span>
                    <span className="navlink"><a href="https://worth.tube" target="_blank" rel="noopener noreferrer">Worthtube</a></span>
                    <span className="navlink"><a href="https://wortheumwallet.com" target="_blank" rel="noopener noreferrer">Wallet</a></span>
                    <span className="navlink"><a href="https://ads.wortheum.news" target="_blank" rel="noopener noreferrer">Ad Network</a></span>
            </div>

            <div className="cntainer">
                <div className="content">
                    <div className="form">
                        <div className="first bx">
                            <div className="feild">
                                <h1 className="head">
                                    Congratulations! Your account is active.
                                </h1>
                                <p className="para">
                                    Your account is created successfully!
                                    Now you can visit wortheum News to Post
                                    or read news articles or use any other
                                    dapps like Worthshots Worthtube or wallet.
                                </p>
                            </div>
                        </div>

                        <div className="second bx">
                            <div className="feild">
                                <span className="parahead bx-head">
                                    Know more about Private Keys!
                                </span>
                                <div className="scrll">
                                    <h1 className="parahead">
                                        Your Posting Private Key:
                                    </h1>
                                    <p className="para">
                                        Posting private key is used for performing
                                        Posting, commenting and upvoting on news 
                                        platform. This key is needed to log in on
                                        Wortheum News, Worthtube, Worthshots!
                                    </p>
                                    <h1 className="parahead">
                                        Your Active Private Key:
                                    </h1>
                                    <p className="para">
                                        Private Active key is used for performing
                                        monetary activites on Wortheum Wallet or
                                        updating profile information on any dapps.
                                    </p>
                                    <h1 className="parahead">
                                        Your Owner Private Keys
                                    </h1>
                                    <p className="para">
                                        Your Owner key is used to change the password
                                        on wortheum wallet and it needed to be saved
                                        somewhere very securely!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinalPage;
