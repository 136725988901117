import React, { useState, useEffect } from "react";
import CreateUser from "./createUsername";
import CreateAccount from "./createAccount";
import "./style.scss";
const Index = (props) => {
  const [isUserVerify, setisUserVerify] = useState(false);
  const [referUsername, setreferUsername] = useState("");
  const [username, setUsername] = useState("");
  useEffect(() => {
    if (props?.match?.path === "/:refer") {
      setreferUsername(props?.match?.params?.refer);
    } else {
      setreferUsername("");
    }
  });
  return (
    <>
      {!isUserVerify && (
        <CreateUser
          setisUserVerify={setisUserVerify}
          username={username}
          setUsername={setUsername}
        />
      )}
      {isUserVerify && (
        <CreateAccount
          isUserVerify={isUserVerify}
          username={username}
          referUsername={referUsername}
          setreferUsername={setreferUsername}
        />
      )}
    </>
  );
};

export default Index;
