import React, {useEffect, useState} from "react";
import logo from "./logo.png";
import "./Verification.css";
import navbutton from "./navbutton.png";
import cross from "./cross.png";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/signup.services";

const openSideNav = () => {
    document.querySelector(".sidenav").style.display = "flex";
};
const closeSideNav = () => {
    document.querySelector(".sidenav").style.display = "none";
};

function Verification() {
    let history = useHistory();

    const [email, setEmail] = useState("")

    useEffect(() => {
        const token = localStorage.getItem("auth-token-wortheum");
        if(!token){
            history.push('/register');
        }
        AuthService.getUserDetails(token).then((response)=>{
            // console.log(response.data);
            setEmail(response.data.user.email)
            if(response.data.user.status!=="Pending"){
                history.push('/');
            }
        }, (error)=>{
            // console.log(error);
            history.push('/register');
        });
    }, [])
    useEffect(() => {
        document.body.style.zoom = "100%";
    }, []);
    
    return (
        <div id = "page3" className="App">
            <video autoPlay muted loop>
                <source
                    src="https://drive.google.com/uc?export=view&id=1CqhzxmDmRRQeq87ShBvUU185UQPAqdm4"
                    type="video/mp4"
                />
            </video>

            <div className="nv">
                <div className="lgo navlink">
                    <img src={logo} alt="logo image" />
                </div>
                <div className="links">
                    <span className="navlink"><a href="https://wortheum.news" target="_blank" rel="noopener noreferrer">Wortheum News</a></span>
                    <span className="navlink"><a href="https://worthshots.com" target="_blank" rel="noopener noreferrer">Worthshots</a></span>
                    <span className="navlink"><a href="https://worth.tube" target="_blank" rel="noopener noreferrer">Worthtube</a></span>
                    <span className="navlink"><a href="https://wortheumwallet.com" target="_blank" rel="noopener noreferrer">Wallet</a></span>
                    <span className="navlink"><a href="https://ads.wortheum.news" target="_blank" rel="noopener noreferrer">Ad Network</a></span>
                    <img
                        src={navbutton}
                        className="nvbtn"
                        alt="nav button"
                        onClick={openSideNav}
                    />
                </div>
            </div>
            <div className="sidenav">
                <img
                    src={cross}
                    className="cross navlink"
                    alt="cross"
                    onClick={closeSideNav}
                />
                    <span className="navlink"><a href="https://wortheum.news" target="_blank" rel="noopener noreferrer">Wortheum News</a></span>
                    <span className="navlink"><a href="https://worthshots.com" target="_blank" rel="noopener noreferrer">Worthshots</a></span>
                    <span className="navlink"><a href="https://worth.tube" target="_blank" rel="noopener noreferrer">Worthtube</a></span>
                    <span className="navlink"><a href="https://wortheumwallet.com" target="_blank" rel="noopener noreferrer">Wallet</a></span>
                    <span className="navlink"><a href="https://ads.wortheum.news" target="_blank" rel="noopener noreferrer">Ad Network</a></span>
            </div>
            <div className="cntainer">
                <div className="content">
                    <div className="form">
                        <div className="first bx">
                            <div className="feild">
                                <span className="head">Verification Required</span>
                                <p className="para">Hello!</p>
                                <p className="para">नमस्ते!</p>
                                <p className="para">
                                    Please check your registerred Email ID{" "}
                                </p>
                                <p className="para">
                                   कृपया अपना पंजीकृत ईमेल आईडी देखें।{" "}
                                </p>
                                <h6 className="parahead">
                                    {email}.
                                </h6>
                                <p className="para">
                                    Please go ahead and activate your account
                                    using the link given in the Verification Email.
                                </p>
                                <p className="para">
                                   कृपया सत्यापन ईमेल में दिए गए लिंक का उपयोग करके अपना खाता सक्रिय करें।
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Verification;
