import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import Welcome from "./component/register/welcome";
import UserAccount from "./component/register";
import Signup from "./component/oldSignup/newsignup";
import Signup2 from "./component/oldSignup/newsignup2";
import Verify from "./component/oldSignup/Verification";
import FinalPage from "./component/oldSignup/FinalPage";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Signup/>
          </Route>

          {/* <Route exact path="/confirm" component={Welcome} /> */}
          {/* <Route exact path="/refer" component={UserAccount} /> */}
          <Route exact path="/register" component={Signup} />
          <Route exact path="/createAccount" component={Signup2} />
          <Route exact path="/verifyMail" component={Verify} />
          <Route exact path="/confirmationPage" component={FinalPage} />
        </Switch>
      </Router>
      <ToastContainer hideProgressBar={true} position="bottom-right" />
    </>
  );
}

export default App;
