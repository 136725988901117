import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import SuggestPassword from "../../../services/GenerateKey";
import AuthService from "../../../services/signup.services";
const UserAccount = ({
  isUserVerify,
  username,
  referUsername,
  setreferUsername,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(SuggestPassword);
  const [phoneNo, setphoneNo] = useState("");

  const [isUserCreated, setisUserCreated] = useState(false);
  const [policyChecked, setpolicyChecked] = useState(false);
  const [isSpinner, setisSpinner] = useState(false);
  const submitAccount = async (e) => {
    if (isUserCreated && policyChecked) {
      e.preventDefault();
      toast.warning(
        "Username is already taken, please reload page and create new user",
        {
          theme: "colored",
        }
      );
      return false;
    }
    // if (email) {
    //   e.preventDefault();
    //   var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //   email.match(mailformat);
    //   toast.warning("You have entered an invalid email address!", {
    //     theme: "colored",
    //   });
    //   return false;
    // }
    if (username && email && phoneNo && password && policyChecked) {
      e.preventDefault();
      setisSpinner(true);
      setTimeout(async () => {
        await AuthService.register(
          username,
          email,
          phoneNo,
          password,
          referUsername
        ).then(
          (response) => {
            toast.success(response.data.message, {
              theme: "colored",
            });
            setisUserCreated(true);
            setisSpinner(false);
            setEmail("");
            setphoneNo("");
            setpolicyChecked(false);
            setreferUsername("");
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            toast.error(resMessage, {
              theme: "colored",
            });
            setisUserCreated(false);
            setisSpinner(false);
          }
        );
      }, 2000);
    }
  };
  return (
    <Form className="form">
      <Form.Group className="input" controlId="formBasicEmail">
        <Form.Label className="label">Your user name*</Form.Label>
        <Form.Control
          className="input"
          type="text"
          name="usernmae"
          value={username}
          readOnly
        />
        <Form.Text className="form_text">
          This will be your wortheum username
        </Form.Text>
      </Form.Group>
      <Form.Group className="input" controlId="formBasicEmail">
        <Form.Label className="label">User Email*</Form.Label>
        <Form.Control
          className="input"
          type="email"
          name="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Form.Text className="form_text">Choose your email for HIVE</Form.Text>
      </Form.Group>
      <Form.Group className="input" controlId="formBasicPhone">
        <Form.Label>Phone Number*</Form.Label>
        <Form.Control
          className="input"
          type="number"
          name="phone number"
          placeholder="Enter you phone number"
          value={phoneNo}
          onChange={(e) => setphoneNo(e.target.value)}
          required
        />
      </Form.Group>
      <input
        className="input"
        type="text"
        name="password"
        value={password}
        readOnly
        required
        hidden
      />
      <Form.Group className="input" controlId="formBasicRefer">
        <Form.Label>Referal User (optional)</Form.Label>
        <Form.Control
          className="input"
          type="text"
          placeholder="Enter your referal username"
          value={referUsername}
          onChange={(e) => setreferUsername(e.target.value)}
        />
      </Form.Group>
      <Form.Text className="link_text">
        <a href="/" className="form_link">
          Terms of Service
        </a>
      </Form.Text>
      <Form.Group className="input" controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          label="I agree to the terms of service"
          name="policy"
          value={policyChecked}
          onChange={(e) => {
            console.log("e", e);
            setpolicyChecked(e.target.checked);
          }}
          required
        />
      </Form.Group>
      <Button
        className="form_btn"
        type="submit"
        onClick={(e) => {
          submitAccount(e);
        }}
      >
        {isSpinner ? <Spinner animation="border" size="sm" /> : "Continue"}
      </Button>
    </Form>
  );
};

export default UserAccount;
