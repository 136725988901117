import "./signup.css";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SuggestPassword from "../../services/GenerateKey";
import AuthService from "../../services/signup.services";
import logo from "../../images/logo.png";
import lgo from "./logo.png";
import navbutton from "./navbutton.png";
import cross from "./cross.png";

const openSideNav = () => {
    document.querySelector(".sidenav").style.display = "flex";
};
const closeSideNav = () => {
    document.querySelector(".sidenav").style.display = "none";
};
const dsteem = require("dsteem");


const Signup = () => {
    const history = useHistory();
    const client = new dsteem.Client("https://bapi.wortheumwallet.com");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setphoneNo] = useState("+91");
    const [password, setPassword] = useState(SuggestPassword);
    const [confirmPswd, setconfirmPswd] = useState("");
    const [userError, setuserError] = useState("");
    const [emailError, setemailError] = useState("");
    const [phonneError, setphoneError] = useState("");
    const [pswdError, setpswdError] = useState("");
    const [iseChecked, setiseChecked] = useState(false);
    const [keyError, setkeyError] = useState("");
    const [userValidate, setuserValidate] = useState(false);
    const [isUserVerify, setisUserVerify] = useState(false);
    const [referUsername, setreferUsername] = useState("");
    const [referalError, setreferalError] = useState("");
    const onChangeUsername = (e) => {
        const username = e.target.value.toLowerCase();
        setUsername(username);
        setuserError("");
        if(username.length<3 || username.length>16){
            setTimeout(() => {
                document.getElementsByClassName('first-after')[0].style.display = 'flex'
            }, 500);
        }
        else{
            document.getElementsByClassName('first-after')[0].style.display = 'none'
        }
    };
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
        setemailError("");
    };
    const onChangePhone = (e) => {
        const phone = e.target.value;
        setphoneNo(phone);
        setphoneError("");
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };
    const onChangeReferal = (e) => {
        const referal = e.target.value;
        setreferUsername(referal);
        setreferalError("");
    };

    const onChangeConfirmPswd = (e) => {
        const confirmPswd = e.target.value;
        setconfirmPswd(confirmPswd);
        setpswdError("");
    };
    const onChangekeyConfirmed = () => {
        if (iseChecked) {
            setiseChecked(false);
        } else {
            setiseChecked(true);
            setkeyError("");
        }
    };

    function ValidateUser(user) {
        if (user==="" || user.length < 3) {
            return {
                valid: false,
                error: "Username should be atleast 3 characters",
            };
        }
        if (user.length > 16) {
            return {
                valid: false,
                error: "Username must be atmost than 16 characters",
            };
        }
        if (!(/^[a-z]/.test(user))) {
            return {
                valid: false,
                error: "Username should start with a letter.",
            };
        }
        if (!(/^[a-z0-9-]*$/.test(user))) {
            return {
                valid: false,
                error: "Username should have only letters, digits, or dashes.",
            };
        }
        if (/--/.test(user)) {
            return {
                valid: false,
                error: "Username should have only one dash in a row.",
            };
        }
        if (!(/[a-z0-9]$/.test(user))) {
            return {
                valid: false,
                error: "Username should end with a letter or digit.",
            };
        }
        // }
        return {
            valid: true,
            error: "Username is valid",
        };
    }
    function ValidateRefUser(user) {
        if (user==="" || user.length < 3) {
            return {
                valid: false,
                error: "Referral Username should be atleast 3 characters",
            };
        }
        if (user.length > 16) {
            return {
                valid: false,
                error: "Referral Username must be atmost than 16 characters",
            };
        }
        if (!(/^[a-z]/.test(user))) {
            return {
                valid: false,
                error: "Referral Username should start with a letter.",
            };
        }
        if (!(/^[a-z0-9-]*$/.test(user))) {
            return {
                valid: false,
                error: "Referral Username should have only letters, digits, or dashes.",
            };
        }
        if (/--/.test(user)) {
            return {
                valid: false,
                error: "Referral Username should have only one dash in a row.",
            };
        }
        if (!(/[a-z0-9]$/.test(user))) {
            return {
                valid: false,
                error: "Referral Username should end with a letter or digit.",
            };
        }
        // }
        return {
            valid: true,
            error: "Referral Username is valid",
        };
    }

    function ValidateEmail(email){
        if (email === "") {
            return {
                valid: false,
                error: "Please enter a valid email id!",
            };
        }
        let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if (!(pattern.test(email))) {
            return {
                valid: false,
                error: "Please enter a valid email id!",
            };
        }
        return {
            valid: true,
            error: "Email Id is valid.",
        };
    }

    function ValidatePhone(phoneNum){
        if (phoneNum === "") {
            return {
                valid: false,
                error: "Please enter a valid phone numbe!",
            };
        }
        let pattern = /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if (!(pattern.test(phoneNum))) {
            return {
                valid: false,
                error: "Please enter a valid phone number!",
            };
        }
        return {
            valid: true,
            error: "Phone Number is valid.",
        };
    }


    function validatedata() {
        let res = ValidateUser(username);
        if(res.valid === false){
            setuserValidate(false);
            Swal.fire({
                icon: "error",
                title: "Invalid Username",
                text: res.error,
            });
            return false
        }

        res = ValidateRefUser(referUsername);
        if(referUsername!=="" && res.valid === false){
            setuserValidate(false);
            Swal.fire({
                icon: "error",
                title: "Invalid Referral",
                text: res.error,
            });
            return false
        }

        res = ValidateEmail(email);
        if(res.valid === false){
            setuserValidate(false);
            Swal.fire({
                icon: "error",
                title: "Invalid Email",
                text: res.error,
            });
            return false
        }

        res = ValidatePhone(phoneNo);
        if(res.valid === false){
            setuserValidate(false);
            Swal.fire({
                icon: "error",
                title: "Invalid Phone Number",
                text: res.error,
            });
            return false
        }

        setuserValidate(true);
        return true
    }

    const AccSearch = async (refName) => {
        // const res = ValidateUser(username);
        // if (!res.valid) {
        //     setuserError(res.error);
        //     // document.getElementById("accInfo").style.color = "red";
        //     return false;
        // } else {
            // if (username.length > 2) {
                const _account = await client.database.call("get_accounts", [
                    [refName],
                ]);
                // console.log(`_account:`, _account, username.length);
                if (_account.length === 0) {
                    // setuserError(" Account is available to register");
                    // setisUserVerify(true);
                    return false;
                    // document.getElementById("accInfo").style.color = "#06d6a9";
                } else {
                    return true;
                    // setuserError(" User cannot be registered/already taken");
                    // setisUserVerify(false);
                }
            // }
        // }
        return false;
    };

    const SubmitUser = async () => {
        if(checked && checked1){
            let res_userValidate = validatedata();
            if (res_userValidate) {
                let res_isUserVerify = await AuthService.checkUsernameAvailable();
                if (!res_isUserVerify.data.isAvailable) {
                    // console.log("res_isUserVerify");
                    Swal.fire({
                        icon: "error",
                        title: "Username is already taken",
                        text: "Please use another username!",
                    });
                    return false;
                }
                let isUsernameAvailable = await AccSearch(username);
                if(isUsernameAvailable){
                    Swal.fire({
                        icon: "error",
                        title: "Username is already taken",
                        text: "Please use another username!",
                    });
                    return false;
                }
                let isReferralCorrect = await AccSearch(referUsername);
            // if (username.length > 2 && res_userValidate && res_isUserVerify) {
                await AuthService.register(
                    username,
                    email,
                    phoneNo,
                    isReferralCorrect ? referUsername : ""
                ).then(
                    (response) => {
                        // console.log(response);
                        // console.log("ok");
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: response.data.message,
                        });
                        localStorage.setItem("auth-token-wortheum", response.data.token);

                        setUsername("");
                        setEmail("");
                        setphoneError("");
                        setPassword("");
                        setconfirmPswd("");
                        setreferUsername("");
                        setisUserVerify(false);
                        setuserValidate(false);
                        history.push("/createAccount");
                    },
                    (error) => {
                        // console.log("oops");
                        // console.log(error);
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: resMessage,
                        });
                        setUsername("");
                        setuserError("");
                        setisUserVerify(false);
                        setuserValidate(false);
                    }
                );
            }
            else{
                // console.log("res_userValidate");
            }}
        else{
            Swal.fire({
                icon: "error",
                title: "Agree to T&C and Privacy Policy",
                text: "Please agree to the T&C and the privacy policy!",
            });
        }
    };
    
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked(!checked);
    };

    const [checked1, setChecked1] = React.useState(false);

    const handleChange1 = () => {
        setChecked1(!checked1);
    };
    React.useEffect(() => {
        if (!checked || !checked1) {
            document.querySelector(".button").style.backgroundColor = "grey";
        } else {
            document.querySelector(".button").style.backgroundColor = "#19C090";
        }
    }, [checked, checked1]);
    React.useEffect(() => {
        document.body.style.zoom = "100%";
    }, []);
    return (
        <div id="page1" className="App">
        <video autoPlay muted loop>
                <source src="https://drive.google.com/uc?export=view&id=1CqhzxmDmRRQeq87ShBvUU185UQPAqdm4" type="video/mp4" />
            </video>

            <div className="nv">
                <div className="lgo navlink">
                    <img src={lgo} alt="logo image" />
                </div>
                <div className="links">
                    <span className="navlink"><a href="https://wortheum.news" target="_blank" rel="noopener noreferrer">Wortheum News</a></span>
                    <span className="navlink"><a href="https://worthshots.com" target="_blank" rel="noopener noreferrer">Worthshots</a></span>
                    <span className="navlink"><a href="https://worth.tube" target="_blank" rel="noopener noreferrer">Worthtube</a></span>
                    <span className="navlink"><a href="https://wortheumwallet.com" target="_blank" rel="noopener noreferrer">Wallet</a></span>
                    <span className="navlink"><a href="https://ads.wortheum.news" target="_blank" rel="noopener noreferrer">Ad Network</a></span>
                    <img
                        src={navbutton}
                        className="nvbtn"
                        alt="nav button"
                        onClick={openSideNav}
                    />
                </div>
            </div>

            <div className="sidenav">
                <img
                    src={cross}
                    className="cross navlink"
                    alt="cross"
                    onClick={closeSideNav}
                />
                    <span className="navlink"><a href="https://wortheum.news" target="_blank" rel="noopener noreferrer">Wortheum News</a></span>
                    <span className="navlink"><a href="https://worthshots.com" target="_blank" rel="noopener noreferrer">Worthshots</a></span>
                    <span className="navlink"><a href="https://worth.tube" target="_blank" rel="noopener noreferrer">Worthtube</a></span>
                    <span className="navlink"><a href="https://wortheumwallet.com" target="_blank" rel="noopener noreferrer">Wallet</a></span>
                    <span className="navlink"><a href="https://ads.wortheum.news" target="_blank" rel="noopener noreferrer">Ad Network</a></span>
            </div>

            <div className="cntainer">
                <div className="content">
                    <div className="form">
                    <span className="detail">Please fill your Details and click on submit button to
                    create account and follow further instructions.</span>
                    <span className="detail">कृपया अपनी जानकारी भरें और खाता बनाने के लिए सबमिट बटन पर
                    क्लिक करें और आगे के निर्देशों का पालन करें।</span>
                        <div className="first bx">
                            <div className="feild">
                                <span className="detail">Username</span>
                                <input
                                    className="place"
                                    value={username}
                                    type="text"
                                    placeholder="Enter your Username"
                                    id="username"
                                    onChange={onChangeUsername}
                                    onBlur={async (e) => {
                                        if (e.target.value.length > 2) {
                                            let isUsernameAvailable = await AccSearch(e.target.value);
                                            if(isUsernameAvailable){
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Username is already taken",
                                                    text: "Please use another username!",
                                                });
                                                return false;
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div className="first-after">
                                The username should contain between 3 to 16 characters
                            </div>
                        </div>

                        <div className="second bx">
                            <div className="feild">
                                <span className="detail">Email Id</span>
                                <input
                                    className="place"
                                    type="text"
                                    placeholder="Enter your Mail ID"
                                    value={email}
                                    onChange={onChangeEmail}
                                />
                            </div>
                            <div className="feild">
                                <span className="detail">Phone No.</span>
                                <input
                                    className="place"
                                    type="text"
                                    value={phoneNo}
                                    onChange={onChangePhone}
                                />
                            </div>
                        </div>
                        <div className="third bx">
                            <div className="feild">
                                <span className="detail">Referral</span>
                                <input
                                    className="place"
                                    type="text"
                                    id="confirmPswd"
                                    placeholder="Enter your referral"
                                    value={referUsername}
                                    onChange={onChangeReferal}
                                />
                            </div>
                        </div>
                    <div>
                        <label className="chk">
                            <input
                                className="chkbx"
                                type="checkbox"
                                checked={checked}
                                onChange={handleChange}
                            />
                            I agree to the{" "}
                            <a href="https://wortheum.news/tos.html" target="_blank" rel="noopener noreferrer">T&C</a>
                        </label>
                    </div>

                    <div>
                        <label className="chk">
                            <input
                                className="chkbx"
                                type="checkbox"
                                checked={checked1}
                                onChange={handleChange1}
                            />
                            I agree to the{" "}
                            <a href="https://wortheum.news/privacy.html" target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </a>
                        </label>
                    </div>
                    </div>
                    <div onClick={SubmitUser}>
                        <input
                            className="button"
                            type="button"
                            value="Submit"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Signup;
