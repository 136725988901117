import React, { useState } from "react";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { ValidateUser } from "../functions/validations";
import { UserData } from "../../../utils/dummyData";
import user from "../../../images/user.svg";
import CompanyLogo from "../../../images/logo.png";
const dsteem = require("dsteem");
const Index = ({ setisUserVerify, username, setUsername }) => {
  //api reference
  const client = new dsteem.Client("https://bapi.wortheumwallet.com");
  //local state
  const [isSpinner, setisSpinner] = useState(false);
  // const [userError, setuserError] = useState("");
  const AccSearch = async (user) => {
    const res = ValidateUser(user);
    if (!res.valid) {
      toast.error(res.error, { theme: "colored" });
      setisSpinner(false);
      return false;
    } else {
      if (username.length > 2) {
        const _account = await client.database.call("get_accounts", [
          [username],
        ]);
        console.log(`_account:`, _account, username.length);
        if (_account.length === 0) {
          toast.success(" Account is available to register", {
            theme: "colored",
          });
          setisUserVerify(true);
        } else {
          toast.warn(" User cannot be registered/already taken", {
            theme: "colored",
          });
          setisUserVerify(false);
        }
      }
    }
  };
  return (
    <>
      <div className="header-section">
        <div className="company-logo">
          <img src={CompanyLogo} />
        </div>
        <div className="accounts-links">
          <ul>
            <li>
              <a href="#"> Explore</a>
            </li>
            <li>
              <a href="#">Communities</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="#"> Login</a>
            </li>
            <li>
              <a href="/register">Register</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="account_section">
        <div className="hero_section">
          <div className="main_section">
            <h3 className="main_heading">
              Create a new Hive account for PeakD.com
            </h3>
            {UserData.map((item, index) => (
              <div
                className="grab_section"
                key={index}
                style={{
                  flexDirection: index === 1 && "row-reverse",
                  justifyContent: index === 1 && "space-between",
                  marginLeft: index === 2 && "20px",
                }}
              >
                <img className="grab_img" src={item.imgUrl} alt="logo" />
                <div
                  className="inner_section"
                  style={{
                    paddingLeft: index === 2 && "20px",
                  }}
                >
                  <h3 className="inner_heading">
                    <span className="inner_span">{item.number}</span>
                    {item.heading}
                  </h3>
                  <p className="inner_para">{item.userAbout}</p>
                </div>
              </div>
            ))}
            <div className="line_div"></div>
            <div className="user_section">
              <h4 className="user_heading">
                Start now finding your account name:
              </h4>
              <InputGroup className="mb-3">
                <img className="user_icon" src={user} alt="user-icon" />
                <FormControl
                  className="input"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value.toLowerCase());
                  }}
                />
              </InputGroup>
              <Button
                className="form_btn"
                disabled={!username}
                onClick={() => {
                  setisSpinner(true);
                  setTimeout(() => {
                    AccSearch(username);
                  }, 2000);
                }}
              >
                {isSpinner ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Check account name "
                )}
              </Button>
            </div>
          </div>
          <div className="signup_section">
            <p className="signup_para">
              Click&nbsp;
              <a className="link_para" href="#">
                here&nbsp;
              </a>
              to choose a different signup method.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
