export function ValidateUser(user) {
  var len = void 0;
  var reWhiteSpace = new RegExp("\\s+");
  let startwithNum = user.match(new RegExp(/^\d/));
  // setuserError('Account is not available to register');
  // let infocolor = 'red';
  var ref = user.split(".");
  for (var i = 0, len = ref.length; i < len; i++) {
    var label = ref[i];
    if (!/^[a-z]/.test(label)) {
      return {
        valid: false,
        error: "Username should start with a letter.",
      };
    }
    if (!/^[a-z0-9-]*$/.test(label)) {
      return {
        valid: false,
        error: "Username should have only letters, digits, or dashes.",
      };
    }
    if (/--/.test(label)) {
      return {
        valid: false,
        error: "Username should have only one dash in a row.",
      };
    }
    if (!/[a-z0-9]$/.test(label)) {
      return {
        valid: false,
        error: "Username should end with a letter or digit.",
      };
    }
  }
  if (user.length < 3) {
    return {
      valid: false,
      error: "Username should be atleast 2 characters",
    };
  }
  if (user.length > 15) {
    return {
      valid: false,
      error: "Username must smaller than 15 characters",
    };
  }
  return {
    valid: true,
    error: "Username is valid",
  };
}
