import axios from "axios";
const API_URL = "https://papi.wortheum.news/api/auth/";

const register = async (username, email, phoneNo, referralUser) => {
  return await axios.post(API_URL + "signup", {
    username,
    email,
    "phoneNumber":phoneNo,
    referralUser,
  });
};

const checkUsernameAvailable = async (username) => {
  return await axios.post(API_URL + "checkAvailable", {username});
};

const saveKeys = async (token, masterPassword, ownerPK, activePK, postingPK, memoPK) => {
  return await axios.post(API_URL + "saveKeys", {masterPassword, ownerPK, activePK, postingPK, memoPK}, {
    headers:{"auth-token": token}
  });
};

const getUserDetails = async (token) => {
  return await axios.post(API_URL + "getUserDetails", {}, {
    headers:{"auth-token": token}
  });
};

const sendMail = async (token) => {
  return await axios.post(API_URL + "sendMail", {}, {
    headers:{"auth-token": token}
  });
};

// const confirmCode = (code) => {
//   return axios.get(API_URL + "confirm" + code).then((response) => {
//     return response.data;
//   });
// };

export default {
  register,
  checkUsernameAvailable,
  saveKeys,
  sendMail,
  getUserDetails,
};
