import React, { useState, useEffect } from "react";
import "./newsignup2.css";
import logo from "./logo.png";
import navbutton from "./navbutton.png";
import cross from "./cross.png";
import AuthService from "../../services/signup.services";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import SuggestPassword from "../../services/GenerateKey";
import Swal from "sweetalert2";
const dsteem = require("dsteem");

const openSideNav = () => {
    document.querySelector(".sidenav").style.display = "flex";
};
const closeSideNav = () => {
    document.querySelector(".sidenav").style.display = "none";
};
function Newsignup2() {
    let opts = {};
    //connect to production server
    opts.addressPrefix = "WTH";
    opts.chainId = "d909c4dfab0369c4ae4f4acaf2229cc1e49b3bba0dffb36a37b6174a6f391e2e";
    const client = new dsteem.Client("https://bapi.wortheumwallet.com");
    const steemClient = new dsteem.Client("https://api.steemit.com");
    const [accountSuccess, setaccountSuccess] = useState(false);
    const [sp, setsp] = useState(process.env.REACT_APP_SP);

    let history = useHistory();

    const [username, setUsername] = useState("");
    const [masterPassword, setMasterPassword] = useState("");
    const [ownerPK, setOwnerPK] = useState("");
    const [activePK, setActivePK] = useState("");
    const [postingPK, setPostingPK] = useState("");
    const [memoPK, setMemoPK] = useState("");

    useEffect(() => {
        //create with Wortheum function
        const submitTx = async (username, password) => {
            const ownerKey = dsteem.PrivateKey.fromLogin(
                username,
                password,
                "owner"
            );
            const activeKey = dsteem.PrivateKey.fromLogin(
                username,
                password,
                "active"
            );
            const postingKey = dsteem.PrivateKey.fromLogin(
                username,
                password,
                "posting"
            );
            const memoKey = dsteem.PrivateKey.fromLogin(
                username,
                password,
                "memo"
            );
            return {
                response: false,
                townerPK: ownerKey.toString(),
                tactivePK: activeKey.toString(),
                tpostingPK: postingKey.toString(),
                tmemoPK: memoKey.toString(),
            };
        };

        const token = localStorage.getItem("auth-token-wortheum");
        if (!token) {
            history.push("/register");
        }
        AuthService.getUserDetails(token).then(
            (response) => {
                // console.log(response.data);
                // generate keys
                const tmasterPassword = SuggestPassword();
                submitTx(response.data.user.username, tmasterPassword).then(
                    (accSuccess) => {
                        let townerPK = accSuccess.townerPK;
                        let tactivePK = accSuccess.tactivePK;
                        let tpostingPK = accSuccess.tpostingPK;
                        let tmemoPK = accSuccess.tmemoPK;
                        // console.log(accSuccess);
                        // if (accSuccess.response) {
                        //     sendDelegation(response.data.user.username);
                        //     if (response.data.user.referralUser) {
                        //         sendWorthpower(response.data.user.referralUser);
                        //     }
                        // }
                        setUsername(response.data.user.username);
                        setMasterPassword(tmasterPassword);
                        setOwnerPK(townerPK);
                        setActivePK(tactivePK);
                        setPostingPK(tpostingPK);
                        setMemoPK(tmemoPK);

                        AuthService.saveKeys(
                            token,
                            tmasterPassword,
                            townerPK,
                            tactivePK,
                            tpostingPK,
                            tmemoPK
                        ).then(
                            (response) => {
                                // console.log(response.data);
                                setUsername(response.data.username);
                            },
                            (error) => {
                                console.log(error);
                                history.push("/register");
                            }
                        );
                    }
                );
            },
            (error) => {
                // console.log(error);
                history.push("/register");
            }
        );
    }, []);

    const handleClickCopy = () => {
        // console.log(username, masterPassword, ownerPK, activePK, postingPK, memoPK);
        let copyText = `Username: ${username}
Master Password: ${masterPassword}
Owner Private Key: ${ownerPK}
Active Private Key: ${activePK}
Posting Private Key: ${postingPK}
Memo Private Key: ${memoPK}`
        // console.log(copyText);
        navigator.clipboard.writeText(copyText);
        Swal.fire({
            icon: "success",
            title: "Text Copied to Clipboard!",
            text: copyText,
        });
        // alert("Copied the text: " + copyText);
    };
    
    const handleClickDownload = () => {
        let copyText = `Username: ${username}
Master Password: ${masterPassword}
Owner Private Key: ${ownerPK}
Active Private Key: ${activePK}
Posting Private Key: ${postingPK}
Memo Private Key: ${memoPK}
        `;
        var blob = new Blob([copyText], {
            type: "text/plain;charset=utf-8;",
        });
        saveAs(blob, "wortheum-keys.txt");
    };

    const handleClickSubmit = () => {
        const token = localStorage.getItem("auth-token-wortheum");
        if (!token) {
            history.push("/register");
        }
        AuthService.sendMail(token).then(
            (response) => {
                // console.log(response.data);
                history.push("/verifyMail");
            },
            (error) => {
                // console.log(error);
                history.push("/register");
            }
        );
    };

    useEffect(() => {
        document.body.style.zoom = "100%";
    }, []);

    return (
        <div id="page2" className="App">
            <video autoPlay muted loop>
                <source
                    src="https://drive.google.com/uc?export=view&id=1CqhzxmDmRRQeq87ShBvUU185UQPAqdm4"
                    type="video/mp4"
                />
            </video>

            <div className="nv">
                <div className="lgo navlink">
                    <img src={logo} alt="logo image" />
                </div>
                <div className="links">
                    <span className="navlink"><a href="https://wortheum.news" target="_blank" rel="noopener noreferrer">Wortheum News</a></span>
                    <span className="navlink"><a href="https://worthshots.com" target="_blank" rel="noopener noreferrer">Worthshots</a></span>
                    <span className="navlink"><a href="https://worth.tube" target="_blank" rel="noopener noreferrer">Worthtube</a></span>
                    <span className="navlink"><a href="https://wortheumwallet.com" target="_blank" rel="noopener noreferrer">Wallet</a></span>
                    <span className="navlink"><a href="https://ads.wortheum.news" target="_blank" rel="noopener noreferrer">Ad Network</a></span>
                    <img
                        src={navbutton}
                        className="nvbtn"
                        alt="nav button"
                        onClick={openSideNav}
                    />
                </div>
            </div>
            <div className="sidenav">
                <img
                    src={cross}
                    className="cross navlink"
                    alt="cross"
                    onClick={closeSideNav}
                />
                    <span className="navlink"><a href="https://wortheum.news" target="_blank" rel="noopener noreferrer">Wortheum News</a></span>
                    <span className="navlink"><a href="https://worthshots.com" target="_blank" rel="noopener noreferrer">Worthshots</a></span>
                    <span className="navlink"><a href="https://worth.tube" target="_blank" rel="noopener noreferrer">Worthtube</a></span>
                    <span className="navlink"><a href="https://wortheumwallet.com" target="_blank" rel="noopener noreferrer">Wallet</a></span>
                    <span className="navlink"><a href="https://ads.wortheum.news" target="_blank" rel="noopener noreferrer">Ad Network</a></span>
            </div>
            <div className="cntainer">
                <div className="content">
                    <div className="form">
                    <span className="detail">Please save your Private keys somewhere very safely and then press continue button.</span>
                    <span className="detail">कृपया अपने पासवर्ड को बहुत सुरक्षित रूप से सहेजें और फिर Continue बटन दबाएं।</span>
                        <div className="first bx">
                            <div className="feild">
                                <span className="head">
                                    Your Username: &nbsp;
                                </span>
                                <span className="detail">{username}</span>
                            </div>
                            <div className="feild">
                                <span className="head">
                                    Master Password: &nbsp;
                                </span>
                                <span className="detail">{masterPassword}</span>
                            </div>
                        </div>

                        <div className="second bx">
                            <h1 className="box-head">Private Keys</h1>
                            <div className="feild">
                                <span className="head">
                                    Owner Private Key: &nbsp;
                                </span>
                                <span className="detail">
                                    {ownerPK
                                        ? ownerPK.toString()
                                        : "creating..."}
                                </span>
                            </div>
                            <div className="feild">
                                <span className="head">
                                    Active Private Key: &nbsp;
                                </span>
                                <span className="detail">
                                    {activePK
                                        ? activePK.toString()
                                        : "creating..."}
                                </span>
                            </div>
                            <div className="feild">
                                <span className="head">
                                    Posting Private Key: &nbsp;
                                </span>
                                <span className="detail">
                                    {postingPK
                                        ? postingPK.toString()
                                        : "creating..."}
                                </span>
                            </div>
                            <div className="feild">
                                <span className="head">
                                    Memo Private Key: &nbsp;
                                </span>
                                <span className="detail">
                                    {memoPK ? memoPK.toString() : "creating..."}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="small-buttons">
                        <input
                            className="button small-button"
                            type="button"
                            value="Download as .txt"
                            onClick={handleClickDownload}
                        />
                        <input
                            className="button small-button"
                            type="button"
                            value="Copy to clipboard"
                            onClick={handleClickCopy}
                        />
                    </div>
                    <div className="button-div">
                        <input
                            className="button large-button"
                            type="button"
                            value="Continue"
                            onClick={handleClickSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Newsignup2;
