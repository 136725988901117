import grab from "../images/user-imgOne.svg";
import hive from "../images/user_imgTwo.svg";
import awesome from "../images/user_imgThree.svg";

export const UserData = [
  {
    imgUrl: grab,
    number: "1.",
    heading: "Grab your username",
    userAbout:
      "PeakD uses Hive Blockchain accounts. The Hive Blockchain is a safe, decentralized database where all your data is stored which no company owns. Let us help you get a free Hive account quickly and easily. Each account is identified by an unique name you can use for every application that uses Hive and to send and receive transfers from your wallet.",
  },
  {
    imgUrl: hive,
    number: "2.",
    heading: "Verify identity with Hiveonboard and store your passwords",
    userAbout:
      " Hiveonboard is a free service to create a new account. A code delivered by Text/SMS is required to make sure you are real. You will receive your Keys (more secure passwords) during the registration and YOU MUST save your keys and keep them safe. ",
  },
  {
    imgUrl: awesome,
    number: "3.",
    heading: "Start using awesome dApps",
    userAbout:
      " Not just PeakD.com... your new Hive account (and keys) can be used on dozens of decentralized applications/websites (dApps). ",
  },
];

export const ListData = [
  { navlist: "learn" },
  { navlist: "create account" },
  { navlist: "explore" },
  { navlist: "referral login" },
];
