import React from "react";
import { Alert, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import Logo from "../../../images/nav_logo.svg";
import Alertlogo from "../../../images/alert.svg";
import Close from "../../../images/close.svg";
import { ListData } from "../../../utils/dummyData";
import Registerform from "./registerform";
import "./index.scss";
const Index = ({ isUserVerify, username, referUsername, setreferUsername }) => {
  const history = useHistory();
  return (
    <div className="page-background">
      <div className="account_section">
        <div className="join_section">
          <div className="header_section">
            <a href="/" className="header_link">
              <img className="header_logo" src={Logo} alt="logo" />
            </a>
            <h4 className="header_heading">
              join the <span className="inner_span">revolution</span>.
            </h4>
          </div>
        </div>
        <div className="nav_section">
          <ul className="nav_lists">
            {ListData.map((item, index, count) => (
              <li
                className="list"
                key={index}
                style={{ minwidth: index === 2 && "587px" }}
              >
                <a
                  href="#"
                  className="link_list"
                  style={{
                    color: index === 1 && "rgba(0, 0, 0, 0.26)",
                    cursor: index === 1 && "context-menu",
                  }}
                >
                  {item.navlist}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="content_section">
          <div className="alert_section">
            <Alert className="alert_content" variant="danger">
              <img className="alert_img" src={Alertlogo} alt="alert_icon" />
              <div className="middle_section">
                <h4 className="hive_heading">
                  Use Hive Keychain Browser Extension
                </h4>
                <div className="content_div">
                  <p className="content_paragraph">
                    It's highly recommended to use the latest version of Hive
                    Keychain Browser Extension which will act as your wallet and
                    safe storage of your keys.
                  </p>
                  <p className="content_paragraph">
                    If you download and install it
                    <span className="inner_spanPara">
                      &nbsp;right now&nbsp;
                    </span>
                    we will automatically import your account and keys into
                    Keychain at the end of signup.
                  </p>
                </div>
              </div>
              <div className="alert_load">
                <Button className="alert_btn" onClick={() => history.go(0)}>
                  reload page
                </Button>
                {/* <img className="close" src={Close} alt="close-logo" /> */}
              </div>
            </Alert>
          </div>
          <Registerform
            isUserVerify={isUserVerify}
            username={username}
            referUsername={referUsername}
            setreferUsername={setreferUsername}
          />
        </div>
        <div className="footer_section">
          <div className="main_footer">
            <p className="footer_para">hiveonboard.com -</p>
            <a href="#" className="footer_link">
              @hiveonboard
            </a>
            <p className={`${"footer_para"} ${"created_by"}`}>- created by</p>
            <a href="#" className="footer_link">
              @roomservice
            </a>
            <span className="span_line">-</span>
            <a href="#" className="footer_link">
              Vote for Witness
            </a>
            <span className="span_line">-</span>
            <a href="#" className="footer_link">
              GitHub
            </a>
            <span className="span_line">-</span>
            <a href="#" className="footer_link">
              API
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
